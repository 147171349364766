<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useMediaControls } from '@vueuse/core';

const { src } = defineProps({
  src: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    default: 500,
  },
  controls: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  volume: {
    type: Number,
    default: 0.5,
  },
});
const video = ref();

let { ended } = useMediaControls(video, {
  src,
});

const emit = defineEmits(['onVideoEnded', 'onVideoMount']);

watch(ended, () => {
  emit('onVideoEnded');
});

onMounted(() => emit('onVideoMount'));
</script>

<template>
  <video ref="video" :width="width" :controls="controls" :autoplay="autoplay" />
</template>
