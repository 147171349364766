<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCourseStore } from '/@/store/course';
import VideoPlayer from '/@/components/VideoPlayer.vue';

const router = useRouter();
const route = useRoute();
const courseStore = useCourseStore();
const introduction = computed(() => courseStore?.course?.introduction);
const courseId = route.params.course;

const onVideoEnded = () =>
  router.push({
    name: 'course-home',
    params: { course: courseStore.course.id },
  });

onMounted(() => {
  courseStore.fetchCourse(courseId);
});
</script>

<template>
  <div
    v-if="introduction"
    class="flex h-full justify-center items-center bg-baseBackground flex-col">
    <VideoPlayer :src="introduction" @on-video-ended="onVideoEnded" />
  </div>
</template>
